// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-dance-js": () => import("./../../../src/pages/gallery/dance.js" /* webpackChunkName: "component---src-pages-gallery-dance-js" */),
  "component---src-pages-gallery-portrait-js": () => import("./../../../src/pages/gallery/portrait.js" /* webpackChunkName: "component---src-pages-gallery-portrait-js" */),
  "component---src-pages-gallery-street-js": () => import("./../../../src/pages/gallery/street.js" /* webpackChunkName: "component---src-pages-gallery-street-js" */),
  "component---src-pages-gallery-wedding-js": () => import("./../../../src/pages/gallery/wedding.js" /* webpackChunkName: "component---src-pages-gallery-wedding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

